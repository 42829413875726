import React from 'react';

// 加载组价

const MonitorLibraryMgrContainer = Loader.loadBusinessComponent('MonitorLibrary','MonitorLibraryMgrContainer');


const KeyPersonMonitorLibMgr = () => {
  const sceneCode = BaseStore.user.appInfo.sceneCode;
  const monitorLabel = Dict.map.monitorLabel[sceneCode];
  const libTypeInfo = {
    libType: 1,
    libLabel: monitorLabel.keyPerson.libLabel,
    libPeople: monitorLabel.keyPerson.libPeople,
    moduleName: 'keyPersonnelLibraryView',
  }
  return (
    <MonitorLibraryMgrContainer 
      libTypeInfo={libTypeInfo}
    />
  )
}

export default KeyPersonMonitorLibMgr;

